'use client';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { memo } from 'react';
import { SliderItem } from 'atoms/SliderItem';
type SliderPanelProps = {
  content: {
    title: string;
    subtitle: string;
    image: string;
    link: {
      url: string;
      openInNewTab: boolean;
      noFollow: boolean;
    };
  }[];
};
export const SliderPanel = memo(({
  content
}: SliderPanelProps) => {
  const settings = {
    dots: false,
    autoplay: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return <section className="w-full">
			<span className="absolute left-1/2 h-[500px] w-full -translate-x-1/2 bg-gray-100 max-sm:h-[360px]" />

			<Slider {...settings} className="absolute bottom-1/2 left-1/2 top-[30px] flex h-[440px] w-screen -translate-x-1/2 items-center max-sm:h-[300px] xl:w-[1280px]">
				{content.map((item, index) => <SliderItem key={index} {...item} />)}
			</Slider>
		</section>;
});
SliderPanel.displayName = 'SliderPanel';