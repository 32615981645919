'use client';

import { type Route } from 'next';
import { useTranslations } from 'next-intl';
import { LinkButton } from 'atoms/LinkButton';
type SliderItemProps = {
  title: string;
  subtitle: string;
  image: string;
  link: {
    url: string;
    openInNewTab: boolean;
    noFollow: boolean;
  };
};
export const SliderItem = ({
  title,
  subtitle,
  image,
  link
}: SliderItemProps) => {
  const t = useTranslations();
  const linkProps = {
    href: link.url as Route,
    ...(link.openInNewTab ? {
      target: '_blank'
    } : {}),
    ...(link.noFollow ? {
      rel: 'nofollow'
    } : {})
  };
  return <div className="relative !flex h-[440px] w-full items-center justify-end max-sm:h-[300px]" data-sentry-component="SliderItem" data-sentry-source-file="SliderItem.tsx">
			<div className="h-[440px] w-full bg-cover bg-center bg-no-repeat lg:w-[730px]" style={{
      backgroundImage: `url(${image})`
    }} />

			<div className="absolute left-1/2 flex h-[409px] w-11/12 -translate-x-1/2 items-center justify-center border-2 border-gray-950 py-6 max-sm:hidden lg:left-0 lg:w-[658px] lg:translate-x-0 lg:justify-start" />

			<div className="absolute left-1/2 top-1/2 flex h-[304px] -translate-x-1/2 -translate-y-1/2 flex-col items-start justify-start max-sm:hidden lg:left-10 lg:translate-x-0 lg:flex-row lg:items-end">
				<div className="h-full w-[525px] bg-gray-950 px-9 py-9 lg:w-[580px]">
					<p className="text-[82px] uppercase leading-none text-white">{title}</p>
					<p className="text-4xl text-white">{subtitle}</p>
				</div>

				<LinkButton {...linkProps} withBorder withArrow data-sentry-element="LinkButton" data-sentry-source-file="SliderItem.tsx">
					{t('see-projects')}
				</LinkButton>
			</div>
		</div>;
};
SliderItem.displayName = 'SliderItem';